
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { Icon, ProcessStatus } from '@/entities/settings'
  import { Auto } from '@/entities/public'
  import { fixPrice } from '@/utils/general'
  import { Appraisal } from '@/entities/purchase'

@Component
  export default class ActionCell extends Cell {
  declare options: {
    set: Record<string, Icon>
  }

  get status (): ProcessStatus {
    const { item } = this
    if (!item) return
    const { status } = item
    return status
  }

  private stockPrice () {
    const { item } = this
    if (!item) return
    const { price } = this.item as Auto
    return fixPrice(price)
  }

  private appraisalPrice () {
    const { price } = this.item as Appraisal
    return fixPrice(price)
  }

  get price () {
    if (this.item instanceof Auto) return this.stockPrice()
    if (this.item instanceof Appraisal) return this.appraisalPrice()

    return this.stockPrice()
  }

  get company () {
    // if (!(this.item instanceof Financing)) return
    //
    // const { company: { alias } } = this.item as Financing
    // return alias
    return undefined
  }

  private stockExecutive () {
    const { isAvailable, price } = this.item as Auto
    if (isAvailable) return

    return price
  }

  get executive () {
    if (this.item instanceof Auto) return this.stockExecutive()
  }

  private soldDate () {
    const { isAvailable, date } = this.item as Auto
    if (isAvailable) return

    return date
  }

  get date () {
    if (this.item instanceof Auto) return this.soldDate()
  }
  }
